<template>
  <div class="main__inner">
    <div class="title">
      <h2>Годовое общее собрание акционеров АО «Литературные чтения»</h2>
      <div class="tooltip tooltip--title">
        <div class="tooltip__icon">
          <svg class="tooltip__svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="#6F7474"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M12 8H12.01" stroke="#6F7474" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11 12H12V16H13" stroke="#6F7474" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="tooltip__text tooltip__text--title">
          <p>
            Годовое общее собрание акционеров в форме заочного голосования. Право голоса по всем вопросам повестки дня собрания имеют владельцы
            обыкновенных акций АО «Литературные чтения».
          </p>
          <p>Дата проведения годового общего собрания: 24 апреля.</p>
        </div>
      </div>
    </div>

    <div class="info-guide info-guide--meeting">
      <div class="info-guide-end info-guide__item">
        <ul class="info-guide-end__list">
          <li class="info-guide-end__list-item">Окончание приема бюллетеней</li>
          <li class="info-guide-end__list-item">23:59 23.04.2021 (МСК)</li>
        </ul>
      </div>
      
    </div>
    <div class="nav-tabs nav-tabs--meeting">
      <ul class="nav-tabs__list">
        <li class="nav-tabs__item" v-for="tab in tabs" :key="tab.id">
          <a
            :class="['nav-tabs__link', { 'nav-tabs__link--active': currentTab === tab.id }]"
            :id="tab.id + '-tab'"
            :href="'#' + tab.id"
            @click="currentTab = tab.id"
            >{{ tab.name }}</a
          >
        </li>
      </ul>
    </div>
    <component :is="currentTabComponent" class="tab" />
    <AskQuestion :isAskQOpen="isAskQOpen" @close-ask-q="isAskQOpen = false" />
  </div>
</template>

<script>
import VoteTab from "@/components/voting/VoteTab";
import AgendaTab from "@/components/voting/AgendaTab";
import MaterialsTab from "@/components/voting/MaterialsTab";
import HowToVoteTab from "@/components/voting/HowToVoteTab";
import AskQuestion from "@/components/voting/AskQuestion";

export default {
  data() {
    return {
      isAskQOpen: false,
      currentTab: "Vote",
      tabs: [
        { id: "Vote", name: "Голосование" },
        { id: "Agenda", name: "Повестка" },
        { id: "Materials", name: "Материалы" },
      ],
    };
  },
  computed: {
    currentTabComponent() {
      return (
        this.currentTab
          .match(/[A-Z][a-z]+/g)
          .join("-")
          .toLowerCase() + "-tab"
      );
    },
  },
  components: {
    VoteTab,
    AgendaTab,
    MaterialsTab,
    AskQuestion,
  },
};
</script>
