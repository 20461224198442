<template>
  <section class="disposal">
    <div class="disposal__inner">
      <div class="title">
        <h3>В вашем распоряжении</h3>
      </div>
      <div class="row">
        <div class="card card--small card--margin card--votes">
          <div class="card__title">
            <h3>{{ qData.vote_count }} голосов</h3>
          </div>
          <div class="card__subtitle">ЛС: 000000000000001473772</div>
          <div class="card__title">
            <h3>Статус голосования:</h3>
          </div>
          <div class="card__subtitle">Вы не отправляли бюллетень</div>
          <router-link class="btn btn--green btn--full btn--100" to="/bulletin"
            >Заполнить бюллетень</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import qData from "@/dummies/bulletin.json";
export default {
  data() {
    return {
      qData,
    };
  },
};
</script>