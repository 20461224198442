<template>
  <section class="meeting-docs">
    <div class="meeting-docs__inner">
      <div class="title">
        <h3>Материалы к собранию</h3>
      </div>
      <div class="meeting-docs__list">
        <ul class="documents-list">
          <li class="documents-list__item" v-for="m in materials" :key="m.id">
            <svg
              class="documents-list__svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
                stroke="#1C2021"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                stroke="#1C2021"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 9H10"
                stroke="#2ba527"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 13H15"
                stroke="#2ba527"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 17H15"
                stroke="#2ba527"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="9"
                  y1="9"
                  x2="10"
                  y2="10"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#45AE00" />
                  <stop offset="1" stop-color="#08944A" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear"
                  x1="9"
                  y1="13"
                  x2="9.32432"
                  y2="14.9459"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#45AE00" />
                  <stop offset="1" stop-color="#08944A" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear"
                  x1="9"
                  y1="17"
                  x2="9.32432"
                  y2="18.9459"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#45AE00" />
                  <stop offset="1" stop-color="#08944A" />
                </linearGradient>
              </defs>
            </svg>
            <a class="documents-list__link" href="#">{{ m.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import qData from "@/dummies/bulletin.json";

export default {
  data() {
    return { materials: qData.data[0].materials };
  },
};
</script>