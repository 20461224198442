<template>
  <section class="meeting-video">
    <div class="meeting-video__inner">
      <div class="title">
        <h3>Руководство по голосованию</h3>
      </div>
      <div class="video">
        <div class="video__inner">
          <div
            class="video__substrate"
            :class="{ 'video__substrate--hide': isPlaying }"
          >
            <div
              class="video__bg"
              :style="{
                backgroundImage: 'url(' + getImgUrl('bg_video.png') + ')',
              }"
            ></div>
            <div class="video__gradient"></div>
            <div class="video__play">
              <button class="video__play-button" @click="isPlaying = true">
                <VideoPlayIcon />
              </button>
            </div>
            <div class="video__description">Инструкция по голосованию</div>
          </div>
          <div class="video__frame">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/rEGOihjqO9w"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VideoPlayIcon from "@/components/icons/VideoPlayIcon";

export default {
  data() {
    return { isPlaying: false };
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/images/" + pic);
    },
  },
  components: { VideoPlayIcon },
};
</script>