<template>
  <section class="meeting-agenda">
    <div class="meeting-agenda__inner">
      <div class="title">
        <h3>Повестка собрания</h3>
      </div>
      <div class="meeting-agenda__accordion">
        <div class="accordion">
          <template v-for="d of qData">
            <div
              :key="d.id"
              class="accordion__header"
              :class="{ 'accordion__header--open': isBlockCollapsed[d.id] }"
              @click="collapseBlock(d.id)"
            >
              <span class="accordion__header-num">{{ d.id + 1 }}.</span
              >{{ d.name }}
              <span class="accordion__header-arrow">
                <svg
                  class="accordion__header-svg"
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#1C2021"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <collapse-transition :key="d.id + qData.length">
              <div
                class="accordion__content accordion__content--num"
                v-show="isBlockCollapsed[d.id]"
              >
                <p v-html="d.description"></p>
                <div v-if="d.candidates">
                  <ol style="padding-left:15px">
                    <li v-for="(candidate, key) in d.candidates" :key="key" style="margin-bottom: 15px;list-style-type: decimal">
                      {{ candidate.name }}<br>
                      <span style="font-size: 14px; opacity: .5; ">{{ candidate.position }}</span>
                    </li>
                  </ol>
                </div>
              </div>
            </collapse-transition>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import qData from "@/dummies/bulletin.json";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";

export default {
  data() {
    return {
      isBlockCollapsed: Array(qData.data.length).fill(false),
      qData: qData.data,
    };
  },
  methods: {
    collapseBlock(id) {
      const newData = [...this.isBlockCollapsed];
      newData[id] = !newData[id];
      this.isBlockCollapsed = newData;
    },
  },
  components: { CollapseTransition },
};
</script>