<template>
  <div id="openModal" class="modal" :class="{ 'modal--open': isAskQOpen }">
    <div class="modal-dialog modal-dialog--appeal modal-dialog--centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-header__title">Новое обращение</h3>
          <a
            class="modal-header__close"
            href="#close"
            title="Close"
            @click="$emit('close-ask-q')"
          >
            <CloseIcon />
          </a>
        </div>
        <div class="modal-body">
          <div class="form-block">
            <form class="form" action="#">
              <div class="form__group">
                <div class="form__item form__item--small">
                  <span class="form__control-name">Фамилия</span>
                  <input
                    class="form__control"
                    v-model="name"
                    type="text"
                    id="surname"
                    name="surname"
                    placeholder=""
                  />
                </div>
                <div class="form__item form__item--small">
                  <span class="form__control-name">Имя</span>
                  <input
                    class="form__control"
                    v-model="surname"
                    type="text"
                    id="name"
                    name="name"
                    placeholder=""
                  />
                </div>
                <div class="form__item form__item--small">
                  <span class="form__control-name">Отчество</span>
                  <input
                    class="form__control"
                    v-model="patronymic"
                    type="text"
                    id="patronymic"
                    name="patronymic"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="form__item form__select">
                <label class="form__label" for="country">Кому обращение</label>
                <select
                  class="form__control form__control-select"
                  v-model="to"
                  id="country"
                  name="country"
                >
                  <option value="australia">Регистратор</option>
                  <option value="canada">Регистратор 2</option>
                  <option value="usa">Регистратор 3</option>
                </select>
                <span class="form__select-arrow">
                  <SelectArrowIcon />
                </span>
              </div>
              <div class="form__item form__textarea">
                <span class="form__control-name"
                  >Опишите, пожалуйста, вопрос</span
                >
                <textarea
                  class="form__control"
                  v-model="description"
                  maxlength="300"
                  id="subject"
                  name="subject"
                  placeholder=""
                ></textarea>
                <div class="form__counter">
                  <div class="form__counter-quantity" id="counter">
                    {{ description ? description.length : 0 }}
                  </div>
                  <div class="form__counter-total">/300</div>
                </div>
              </div>
              <div class="form__check-block">
                <div class="form__check">
                  <div
                    class="form__check-box"
                    :class="{ 'form__check-box--active': answerByPhone }"
                  ></div>
                  <input
                    class="form__check-input"
                    v-model="answerByPhone"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label class="form__check-label" for="defaultCheck1">
                    Получить ответ по телефону
                  </label>
                </div>
                <div class="form__check">
                  <div
                    class="form__check-box"
                    :class="{ 'form__check-box--active': answerByEmail }"
                  ></div>
                  <input
                    class="form__check-input"
                    v-model="answerByEmail"
                    type="checkbox"
                    value=""
                    id="defaultCheck2"
                  />
                  <label class="form__check-label" for="defaultCheck2">
                    Получить ответ по почте
                  </label>
                </div>
              </div>
              <div class="form__file">
                <div class="form__file-item">
                  <div class="form__file-svg">
                    <PaperClipIcon />
                  </div>
                  <div class="form__file-text">Прикрепить документ</div>
                </div>
                <input
                  class="form__control-file"
                  @change="addFile"
                  type="file"
                  id="exampleFormControlFile1"
                />
              </div>
              <div class="form__item form__submit">
                <input
                  class="btn btn--100"
                  :class="!validate() ? 'btn--disabled' : 'btn--full'"
                  @click.prevent="submit"
                  type="submit"
                  value="Отправить обращение"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/icons/CloseIcon";
import SelectArrowIcon from "@/components/icons/SelectArrowIcon";
import PaperClipIcon from "@/components/icons/PaperClipIcon";

export default {
  data() {
    return {
      name: "",
      surname: "",
      patronymic: "",
      to: null,
      description: "",
      answerByPhone: false,
      answerByEmail: false,
      file: null,
    };
  },
  props: {
    isAskQOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      return {
        name: this.name,
        surname: this.surname,
        patronymic: this.patronymic,
        to: this.to,
        description: this.description,
        answerByPhone: this.answerByPhone,
        answerByEmail: this.answerByEmail,
        file: this.file,
      };
    },
    validate() {
      return (
        this.name &&
        this.surname &&
        this.patronymic &&
        this.to &&
        this.description &&
        (this.answerByPhone || this.answerByEmail)
      );
    },
    addFile(event) {
      this.file = event.target.files[0];
    },
  },
  components: {
    CloseIcon,
    SelectArrowIcon,
    PaperClipIcon,
  },
};
</script>